<template>
  <!-- Modal -->
  <div class="modal fade in" id="notification">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-form">
          <button class="close" data-dismiss="modal"></button>
          <p>Please ensure you have checked the dimensions of all selected appliances to suit the consumer’s needs.</p>
        </div>
      </div>
    </div>
  </div>
  <!-- / Modal -->
</template>
<script>

export default {
  name: "NotificationModal",
  data: function () {
    return {
    };
  },
  methods: {
  }
};
</script>
<style scoped>
</style>