var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal fade in", attrs: { id: "notification" } },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-form" }, [
              _c("button", {
                staticClass: "close",
                attrs: { "data-dismiss": "modal" }
              }),
              _c("p", [
                _vm._v(
                  "Please ensure you have checked the dimensions of all selected appliances to suit the consumer’s needs."
                )
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }