<template>
  <div class="dropdown" style="padding-right:15px">
    <button
      type="button"
      id="subCategoyDropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      class="text-nowrap text-truncate"
    >
      {{ (isSubCategorySelected)? selectedSubCategory.name : "Select Sub-Category" }}
    </button>
    <div class="dropdown-menu" aria-labelledby="subCategoyDropdownMenuButton">
      <ul>
        <li v-for="subCategory in subCategories" :key="subCategory.id">
          <div class="checkbox">
            <input
              class="form-check-input" 
              type="radio"
              :id="'sub-cat-' + subCategory.id"
              :value = subCategory.id
              v-model="selectedSubCategoryId"
              @change="selectSubCategory(subCategory)"
            />
            <label :for="'sub-cat-' + subCategory.id">{{ subCategory.name }}</label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CategoryService from "@/services/category.service";

export default {
  name: "SubCategoriesDropdown",
  components: {},
  props: [
      "parentCategory"
      ],
  data: function () {
    return {
      subCategories: [],
      selectedSubCategoryId: null,
    };
  },
  mounted() {
    this.getSubCategories();
  },
  computed: {
     selectedSubCategory() {
       return this.$store.state.subCategory.selectedSubCategory;
     },

     isSubCategorySelected() {
      return this.$store.state.subCategory.selectedSubCategory != null;
    },
  },
  methods: {
    getSubCategories: function () {
      CategoryService.children(this.parentCategory.id).then((list) => {
        this.subCategories = list;
        if (this.selectedSubCategory == null) {
          this.$store.dispatch("subCategory/select", this.subCategories[0]);
          this.selectedSubCategoryId = this.subCategories[0].id;
          this.$emit('changeSubCategory', this.subCategories[0]);
        }
        else {
          this.selectedSubCategoryId = this.selectedSubCategory.id;
        }
      });
    },

    selectSubCategory: function (subCategory) {
      this.$store.dispatch("subCategory/select", subCategory);
      this.$emit('changeSubCategory', subCategory);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>