<template>
  <ul class="list-inline">
    <li v-for="brand in brands" :key="brand.id">
      <div class="checkbox mr-2">
        <input
          type="checkbox"
          :id="'brand-' + brand.id"
          :value="brand.id"
          v-model="selectedBrandIds"
          :disabled="isBrandSelected(brand) && selectedBrandIds.length < 2"
          @change="brandsChanged"
        />
        <label :for="'brand-' + brand.id">{{ brand.name }}</label>
      </div>
    </li>
  </ul>
</template>
<script>
import _ from "lodash";
import BrandService from "@/services/brand.service";
export default {
  name: "BrandsSelector",
  components: {},
  props: [],
  data: function () {
    return {
      brands: [],
      selectedBrandIds: [],
    };
  },
  computed: {
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
  },
  mounted() {
    this.getBrands();
  },
  methods: {
    getBrands: function () {
      BrandService.list().then((list) => {
        this.brands = list;
        if(this.selectedBrands.length == 0) {
          this.$store.dispatch("brand/selectBrands", this.brands);
          this.$emit("changeBrands", this.brands);
        }
        this.selectedBrandIds = _.map(this.selectedBrands, "id");
      });
    },
    isBrandSelected(brand) {
      return _.includes(this.selectedBrandIds, brand.id);
    },

    brandsChanged: function () {
      let sBrands = _.filter(this.brands, (brand) => {
        return _.includes(this.selectedBrandIds, brand.id);
      });
      this.$store.dispatch("brand/selectBrands", sBrands);
      this.$emit("changeBrands", sBrands);
    },
  },
};
</script>