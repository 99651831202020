<template>
  <BaseLayout>
    <div id="content">
      <div class="container">
        <header class="mb-5">
          <h1 class="theme-text">
            <template v-if="$route.query.q == null">
              {{
                selectedCategory != null
                  ? selectedCategory.name
                  : "All Products"
              }}
            </template>
            <template v-else>
              Results for: {{ $route.query.q }} (<button
                v-on:click="clearQuery"
                class="btn btn-link btn-sm"
                style="padding: 0"
              >
                Clear</button
              >)
            </template>
          </h1>
        </header>
        <div class="filter1 mb-4">
          <div class="row">
            <div class="col">
              <div class="filter-wrapper">
                <span>Categories:</span>
                <CategoriesDropdown
                  ref="categoryDropdown"
                  :allowBlank="true"
                  @changeCategory="categoryChanged"
                />
              </div>
            </div>
            <div class="col" v-if="selectedCategory != null">
              <div class="filter-wrapper">
                <span class="text-nowrap">Sub-Cats:</span>
                <SubCategoriesDropdown
                  :key="selectedCategory.id"
                  ref="subCategoryDropdown"
                  :parentCategory="selectedCategory"
                  @changeSubCategory="subCategoryChanged"
                />
              </div>
            </div>
            <div class="col">
              <div class="filter-wrapper">
                <span class="text-nowrap">Brands:</span>
                <BrandsSelector
                  ref="brandsSelector"
                  @changeBrands="brandsChanged"
                />
              </div>
            </div>
            <div class="col d-none">
              <div class="filter-wrapper">
                <span class="text-nowrap">Sort By:</span>
                <div class="selectbox1">
                  <select>
                    <option>Please select</option>
                    <option>Price: High to Low</option>
                    <option>Price: Low to High</option>
                    <option>Date: Newest</option>
                    <option>Date: Oldest</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 mb-4 mb-lg-0">
            <DefaultFilters v-if="selectedSubCategory == null" />
            <DishwasherFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Dishwashers'
              "
            />
            <CordlessVacuumCleanerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Cordless Vacuum Cleaners'
              "
            />

            <CoffeeMachineFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name ==
                    'Built-in fully automatic coffee machines'
              "
            />

            <FreestandingFridgeFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Freestanding Fridges'
              "
            />

            <IntegratedFridgeFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Integrated Fridges'
              "
            />

            <AmericanStyleFridgeFreezerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'American Style Fridge Freezers'
              "
            />

            <FreestandingFreezerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Freestanding Freezers'
              "
            />

            <IntegratedFreezerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Integrated Freezers'
              "
            />

            <WashingMachineFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Washing Machines'
              "
            />

            <HeatPumpDryerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Heat Pump Dryers'
              "
            />

            <WasherDryerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Washer Dryers'
              "
            />

            <CondenserTumbleDryerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Condenser Tumble Dryers'
              "
            />

            <OvenFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Ovens'
              "
            />

            <HobsFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Hobs'
              "
            />

            <FreestandingMicrowaveFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Freestanding Microwaves'
              "
            />

            <BuiltinOvenwithMicrowaveFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Built-in Ovens with Microwave'
              "
            />

            <DoubleOvenFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Double Ovens'
              "
            />

            <WarmingDrawerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Warming Drawers'
              "
            />

            <ExtractorFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Extractors'
              "
            />

            <IntegratedFridgeFreezerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Integrated Fridge Freezers'
              "
            />

            <FridgeFreezerFilters
              @changeFilters="filtersChanged"
              v-if="
                selectedSubCategory != null &&
                  selectedSubCategory.name == 'Fridge Freezers'
              "
            />
          </div>
          <div class="col-lg-9 product-details">
            <div class="row page-view">
              <div class="col-md-6">
                <span class="showing"
                  >Showing {{ products.length }} of {{ rows }} products</span
                >
              </div>
              <div class="col-md-6">
                <div class="page-view-btns">
                  <button
                    v-on:click="layout = 'grid'"
                    v-bind:class="{ active: layout == 'grid' }"
                  >
                    <span class="fa fa-th"></span>
                  </button>
                  <button
                    v-on:click="layout = 'list'"
                    v-bind:class="{ active: layout == 'list' }"
                  >
                    <span class="fa fa-list-ul"></span>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="layout === 'list'">
              <div
                class="product"
                v-for="product in products"
                v-bind:product="product"
                v-bind:key="product.id"
              >
                <div class="image">
                  <img
                    :src="
                      'https://media3.bosch-home.com/Product_Shots/600x337/' +
                        product.featured_image +
                        '.jpg'
                    "
                    :alt="product.code"
                    width="126"
                  />
                  <span v-if="product.is_featured" class="featured-tag rotated"
                    >Buy Direct Model</span
                  >
                </div>
                <div class="details">
                  <div class="row">
                    <div class="col-xl-9 col-lg-8">
                      <h4>
                        {{ product.brand.name }} {{ product.series }}
                        {{ product.code }} {{ product.name }}
                      </h4>
                      <!-- <ul class="features">
                        <li
                          v-for="feature in keyFeatures(product)"
                          v-bind:key="feature.value"
                        >
                          {{ feature.attribute.name }} - {{ feature.value }}
                        </li>
                      </ul> -->
                    </div>
                    <div class="col-xl-3 col-lg-4 col2">
                      <div class="wrapper">
                        <div class="row align-items-center">
                          <div class="col-lg-12 col-md-6">
                            <div class="checkbox">
                              <input
                                type="checkbox"
                                :value="product.id"
                                v-model="selectedProductIds"
                                :id="'list-product-' + product.id"
                                @change="
                                  productComparisionChanged(product, $event)
                                "
                              />
                              <label :for="'list-product-' + product.id"
                                >Compare</label
                              >
                            </div>
                          </div>
                        </div>
                        <router-link
                          :to="/product/ + product.id"
                          :id="product.code + '-product-view-button'"
                          class="theme-bg btn btn-primary"
                          >View</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="layout === 'grid'">
              <div class="row grid-view-wrapper">
                <div
                  class="col-xl-4 col-md-6"
                  v-for="product in products"
                  :key="product.id"
                >
                  <div class="product">
                    <div class="row no-gutters">
                      <div class="col-12 product-image">
                        <div class="image">
                          <img
                            :src="
                              'https://media3.bosch-home.com/Product_Shots/600x337/' +
                                product.featured_image +
                                '.jpg'
                            "
                            :alt="product.code"
                            height="91"
                          />
                          <span
                            v-if="product.is_featured"
                            class="featured-tag rotated"
                            >Buy Direct Model</span
                          >
                        </div>
                      </div>
                      <div class="col-12 description">
                        <div class="details">
                          <div class="row">
                            <div class="col-12">
                              <h4 class="theme-text">
                                {{ product.brand.name }} {{ product.series }}
                                {{ product.code }} {{ product.name }}
                              </h4>

                              <!-- <ul class="features">
                                <li
                                  v-for="feature in keyFeatures(product)"
                                  v-bind:key="feature.value"
                                >
                                  {{ feature.attribute.name }} -
                                  {{ feature.value }}
                                </li>
                              </ul> -->
                            </div>
                            <div class="col-12 mt-auto">
                              <div class="wrapper">
                                <div class="row align-items-center">
                                  <div class="col-6">
                                    <div class="checkbox">
                                      <input
                                        type="checkbox"
                                        :value="product.id"
                                        v-model="selectedProductIds"
                                        :id="'grid-product-' + product.id"
                                        @change="
                                          productComparisionChanged(
                                            product,
                                            $event
                                          )
                                        "
                                      />
                                      <label :for="'grid-product-' + product.id"
                                        >Compare</label
                                      >
                                    </div>
                                  </div>
                                </div>
                                <router-link
                                  :to="/product/ + product.id"
                                  :id="product.code + '-product-view-button'"
                                  class="btn btn-primary"
                                  >View</router-link
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="pageChanged"
            ></b-pagination>
          </div>
        </div>
      </div>
      <NotificationModal />
    </div>
  </BaseLayout>
</template>
<script>
/* global $ */
import _ from "lodash";
import CategoriesDropdown from "@/components/CategoriesDropdown";
import SubCategoriesDropdown from "./SubCategoriesDropdown";
import BrandsSelector from "./BrandsSelector";
import BaseLayout from "@/layouts/base";
import ProductService from "@/services/product.service";
import NotificationModal from "./NotificationModal";

export default {
  name: "Products",
  components: {
    BaseLayout,
    CategoriesDropdown,
    SubCategoriesDropdown,
    BrandsSelector,
    NotificationModal,
    DishwasherFilters: () => import("./DishwasherFilters"),
    CordlessVacuumCleanerFilters: () =>
      import("./CordlessVacuumCleanerFilters"),
    CoffeeMachineFilters: () => import("./CoffeeMachineFilters"),
    FreestandingFridgeFilters: () => import("./FreestandingFridgeFilters"),
    IntegratedFridgeFilters: () => import("./IntegratedFridgeFilters"),
    AmericanStyleFridgeFreezerFilters: () =>
      import("./AmericanStyleFridgeFreezerFilters"),
    FreestandingFreezerFilters: () => import("./FreestandingFreezerFilters"),
    IntegratedFreezerFilters: () => import("./IntegratedFreezerFilters"),
    WashingMachineFilters: () => import("./WashingMachineFilters"),
    HeatPumpDryerFilters: () => import("./HeatPumpDryerFilters"),
    WasherDryerFilters: () => import("./WasherDryerFilters"),
    CondenserTumbleDryerFilters: () => import("./CondenserTumbleDryerFilters"),
    OvenFilters: () => import("./OvenFilters"),
    HobsFilters: () => import("./HobsFilters"),
    BuiltinOvenwithMicrowaveFilters: () =>
      import("./BuiltinOvenwithMicrowaveFilters"),
    FreestandingMicrowaveFilters: () =>
      import("./FreestandingMicrowaveFilters"),
    DoubleOvenFilters: () => import("./DoubleOvenFilters"),
    WarmingDrawerFilters: () => import("./WarmingDrawerFilters"),
    ExtractorFilters: () => import("./ExtractorFilters"),
    IntegratedFridgeFreezerFilters: () =>
      import("./IntegratedFridgeFreezerFilters"),
    FridgeFreezerFilters: () => import("./FridgeFreezerFilters"),
    DefaultFilters: () => import("./DefaultFilters"),
  },
  data: function() {
    return {
      products: [],
      perPage: 10,
      currentPage: 1,
      rows: 0,
      filters: {},
      layout: "grid",
      selectedProductIds: _.map(
        this.$store.state.product.selectedProducts,
        "id"
      ),
    };
  },

  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.getProducts();
  },
  watch: {
    // call again the method if the route changes
    $route: function() {
      this.currentPage = 1;
      this.getProducts();
    },
    // filters: function() {
    //   this.filtersChanged();
    // }
  },
  mounted() {
    $("#notification").modal("show");
  },
  computed: {
    selectedProducts() {
      return this.$store.state.product.selectedProducts;
    },

    selectedCategory() {
      return this.$store.state.category.selectedCategory;
    },

    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedBrand() {
      return this.$store.state.brand.selectedBrand;
    },
    isBrandSelected() {
      return this.$store.state.brand.selectedBrand != null;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    },
  },
  methods: {
    getProducts: function() {
      if (this.$route.query.q != null) {
        this.filters["search"] = this.$route.query.q;
      } else {
        this.filters = _.omit(this.filters, ["search"]);
      }

      if (this.selectedBrands.length > 0) {
        this.filters["brand__in"] = _.map(this.selectedBrands, "id").toString();
      } else {
        this.filters = _.omit(this.filters, ["brand__in"]);
      }

      if (this.selectedCategory != null) {
        this.filters["category__tn_parent"] = this.selectedCategory.id;
      } else {
        this.filters = _.omit(this.filters, ["category__tn_parent"]);
      }

      if (this.selectedSubCategory != null) {
        this.filters["category"] = this.selectedSubCategory.id;
      } else {
        this.filters = _.omit(this.filters, ["category"]);
      }

      this.filters["page"] = this.currentPage;

      this.filters["ordering"] = "-is_featured";
      this.filters = { ...this.filters, ...this.selectedFilters };
      this.filters = _.omitBy(this.filters, (filter) => {
        return filter == null || filter == "";
      });

      ProductService.list(this.filters).then((response) => {
        this.products = response.results;
        this.rows = response.count;
      });
    },

    keyFeatures(product) {
      return _.filter(product.product_attributes, { is_key_feature: true });
    },

    categoryChanged() {
      if (this.selectedCategory == null) {
        this.currentPage = 1;
        this.getProducts();
      }
    },

    subCategoryChanged() {
      this.currentPage = 1;
      this.filters = {};
      this.$store.dispatch("filters/remove");
      this.getProducts();
    },

    brandsChanged() {
      this.currentPage = 1;
      this.getProducts();
    },

    pageChanged(page) {
      this.currentPage = page;
      this.getProducts();
    },

    filtersChanged(filters) {
      this.filters = { ...this.filters, ...filters };
      this.$store.dispatch("filters/add", this.filters);
      this.currentPage = 1;
      this.getProducts();
    },

    clearQuery() {
      this.currentPage = 1;
      this.$router.push("/products");
    },

    productComparisionChanged(product, event) {
      if (event.target.checked) {
        if (this.selectedProducts.length > 0) {
          if (this.selectedProducts[0].category_id == product.category.id) {
            ProductService.compareEvent(product.id).then(() => {
              this.$store.dispatch("product/add", product);
            });
          } else {
            alert("You can compare products that are in same category.");
          }
        } else {
          ProductService.compareEvent(product.id).then(() => {
            this.$store.dispatch("product/add", product);
          });
        }
      } else {
        this.$store.dispatch("product/remove", product);
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
