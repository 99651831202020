import axios from 'axios';

class CategoryService {
  all(params) {
    return axios
      .get('api/categories/', { params: params })
      .then(response => {
        return response.data;
      });
  }

  roots() {
    return axios
      .get('api/categories/', { params: {
        'tn_parent__isnull': true
      }})
      .then(response => {
        return response.data;
      });
  }

  children(parentId) {
    return axios
      .get('api/categories/', { params: {
        'tn_parent': parentId
      }})
      .then(response => {
        return response.data;
      });
  }
  
}

export default new CategoryService();