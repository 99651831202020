<template>
  <div class="dropdown" style="padding-right: 15px">
    <button
      type="button"
      id="categoyDropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      class="text-nowrap text-truncate"
    >
      {{ selectedCategory != null ? selectedCategory.name : "Any Category" }}
    </button>
    <div class="dropdown-menu" aria-labelledby="categoyDropdownMenuButton">
      <ul>
        <li v-if="allowBlank">
          <div class="checkbox">
            <input
              class="form-check-input"
              type="radio"
              id="cat-any"
              :value="null"
              v-model="selectedCategoryId"
              @change="categoryChanged(null)"
            />
            <label for="cat-any">Any Category</label>
          </div>
        </li>

        <li v-for="category in categories" :key="category.id">
          <div class="checkbox">
            <input
              class="form-check-input"
              type="radio"
              :id="'cat-' + category.id"
              :value="category.id"
              v-model="selectedCategoryId"
              @change="categoryChanged(category)"
            />
            <label :for="'cat-' + category.id">{{ category.name }}</label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CategoryService from "@/services/category.service";

export default {
  name: "CategoriesDropdown",
  components: {},
  props: {
    allowBlank: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      categories: [],
      selectedCategoryId: null,
    };
  },
  mounted() {
    this.getCategories();
    if (this.isCategorySelected) {
      this.selectedCategoryId = this.selectedCategory.id;
    }
  },
  computed: {
    selectedCategory() {
      return this.$store.state.category.selectedCategory;
    },

    isCategorySelected() {
      return this.$store.state.category.selectedCategory != null;
    },
  },
  methods: {
    getCategories: function () {
      CategoryService.roots().then((list) => {
        this.categories = list;
      });
    },

    categoryChanged: function (category) {
      this.$store.dispatch("category/select", category);
      this.$store.dispatch("subCategory/diselect");
      this.$emit("changeCategory", category);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>