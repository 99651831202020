var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown", staticStyle: { "padding-right": "15px" } },
    [
      _c(
        "button",
        {
          staticClass: "text-nowrap text-truncate",
          attrs: {
            type: "button",
            id: "categoyDropdownMenuButton",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.selectedCategory != null
                  ? _vm.selectedCategory.name
                  : "Any Category"
              ) +
              " "
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": "categoyDropdownMenuButton" }
        },
        [
          _c(
            "ul",
            [
              _vm.allowBlank
                ? _c("li", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategoryId,
                            expression: "selectedCategoryId"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "radio", id: "cat-any" },
                        domProps: {
                          value: null,
                          checked: _vm._q(_vm.selectedCategoryId, null)
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.selectedCategoryId = null
                            },
                            function($event) {
                              return _vm.categoryChanged(null)
                            }
                          ]
                        }
                      }),
                      _c("label", { attrs: { for: "cat-any" } }, [
                        _vm._v("Any Category")
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._l(_vm.categories, function(category) {
                return _c("li", { key: category.id }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedCategoryId,
                          expression: "selectedCategoryId"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: { type: "radio", id: "cat-" + category.id },
                      domProps: {
                        value: category.id,
                        checked: _vm._q(_vm.selectedCategoryId, category.id)
                      },
                      on: {
                        change: [
                          function($event) {
                            _vm.selectedCategoryId = category.id
                          },
                          function($event) {
                            return _vm.categoryChanged(category)
                          }
                        ]
                      }
                    }),
                    _c("label", { attrs: { for: "cat-" + category.id } }, [
                      _vm._v(_vm._s(category.name))
                    ])
                  ])
                ])
              })
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }