var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "list-inline" },
    _vm._l(_vm.brands, function(brand) {
      return _c("li", { key: brand.id }, [
        _c("div", { staticClass: "checkbox mr-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedBrandIds,
                expression: "selectedBrandIds"
              }
            ],
            attrs: {
              type: "checkbox",
              id: "brand-" + brand.id,
              disabled:
                _vm.isBrandSelected(brand) && _vm.selectedBrandIds.length < 2
            },
            domProps: {
              value: brand.id,
              checked: Array.isArray(_vm.selectedBrandIds)
                ? _vm._i(_vm.selectedBrandIds, brand.id) > -1
                : _vm.selectedBrandIds
            },
            on: {
              change: [
                function($event) {
                  var $$a = _vm.selectedBrandIds,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = brand.id,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.selectedBrandIds = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.selectedBrandIds = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.selectedBrandIds = $$c
                  }
                },
                _vm.brandsChanged
              ]
            }
          }),
          _c("label", { attrs: { for: "brand-" + brand.id } }, [
            _vm._v(_vm._s(brand.name))
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }